// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

type DataProps = {
    site: {
        buildTime: string
    }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data, path }) => (
    <Layout>
        <Seo title="Niklas Köhler | Frontend Developer aus Haan | niklaskoehler.de" />

    <div className='md:my-32'>
        <h1 className='text-center md:text-3xl text-blueGray-500 dark:text-gray-300'>
            Hi! Mein Name ist <strong className='text-primary dark:text-gray-500'>Niklas Köhler</strong> und ich bin Frontend-Entwickler bei <a href="https://www.brainbits.net/"
                className="underline text-brainbits"
                target="_blank">brainbits</a>
        </h1>

            <figure className="w-full mx-auto my-12 md:w-2/3">
                <div className="flex justify-center">
                    <a className="flex items-center justify-center w-12 h-12 p-3 bg-gray-200 rounded-full dark:hover:bg-gray-600 group"
                        href="https://www.xing.com/profile/Niklas_Koehler"
                        target="_blank"
                        title="Niklas Köhler auf Xing"
                        rel="noopener noreferrer">
                        <svg viewBox="0 0 22 28"
                            className="w-full h-auto fill-gray-500 dark:fill-gray-900 group-hover:fill-primary dark:group-hover:fill-gray-100">
                            <path d="M9.328 10.422q-.156.281-4.016 7.125-.422.719-1.016.719H.562q-.328 0-.484-.266t0-.562l3.953-7q.016 0 0-.016L1.515 6.063q-.187-.344-.016-.578.141-.234.5-.234h3.734q.625 0 1.031.703zM21.922.391q.172.25 0 .578l-8.25 14.594v.016l5.25 9.609q.172.313.016.578-.156.234-.5.234h-3.734q-.656 0-1.031-.703l-5.297-9.719L16.673.859q.391-.703 1-.703h3.766q.344 0 .484.234z" />
                        </svg>
                    </a>
                    <a className="flex items-center justify-center w-12 h-12 p-3 ml-3 bg-gray-200 rounded-full dark:hover:bg-gray-600 group"
                        href="https://www.linkedin.com/in/niklas-k%C3%B6hler-erkrath/"
                        target="_blank"
                        title="Niklas Köhler auf LinkedIn"
                        rel="noopener noreferrer">
                        <svg viewBox="0 0 384 448"
                            className="w-full h-auto fill-gray-500 dark:fill-gray-900 group-hover:fill-primary dark:group-hover:fill-gray-100">
                            <path d="M87.25 156.25V404H4.75V156.25h82.5zm5.25-76.5c.25 23.75-17.75 42.75-46.5 42.75h-.5c-27.75 0-45.5-19-45.5-42.75C0 55.5 18.5 37 46.5 37c28.25 0 45.75 18.5 46 42.75zM384 262v142h-82.25V271.5c0-33.25-12-56-41.75-56-22.75 0-36.25 15.25-42.25 30-2 5.5-2.75 12.75-2.75 20.25V404h-82.25c1-224.5 0-247.75 0-247.75H215v36h-.5c10.75-17 30.25-41.75 74.75-41.75C343.5 150.5 384 186 384 262z" />
                        </svg>
                    </a>
                    <a className="flex items-center justify-center w-12 h-12 p-3 ml-3 bg-gray-200 rounded-full dark:hover:bg-gray-600 group"
                        href="https://github.com/NikFlip"
                        target="_blank"
                        title="Niklas Köhler auf Github"
                        rel="noopener noreferrer">
                        <svg viewBox="0 0 22 28"
                            className="w-full h-auto fill-gray-500 dark:fill-gray-900 group-hover:fill-primary dark:group-hover:fill-gray-100">
                            <path
                                d="M24 14q0 3.922-2.289 7.055t-5.914 4.336q-.422.078-.617-.109t-.195-.469v-3.297q0-1.516-.812-2.219.891-.094 1.602-.281t1.469-.609 1.266-1.039.828-1.641.32-2.352q0-1.891-1.234-3.219.578-1.422-.125-3.187-.438-.141-1.266.172t-1.437.688l-.594.375q-1.453-.406-3-.406t-3 .406q-.25-.172-.664-.422T7.033 7.18t-1.344-.211q-.688 1.766-.109 3.187-1.234 1.328-1.234 3.219 0 1.328.32 2.344t.82 1.641 1.258 1.047 1.469.609 1.602.281q-.625.562-.766 1.609-.328.156-.703.234t-.891.078-1.023-.336-.867-.977q-.297-.5-.758-.812t-.773-.375l-.313-.047q-.328 0-.453.07t-.078.18.141.219.203.187l.109.078q.344.156.68.594t.492.797l.156.359q.203.594.688.961t1.047.469 1.086.109.867-.055l.359-.063q0 .594.008 1.391t.008.844q0 .281-.203.469t-.625.109q-3.625-1.203-5.914-4.336T.003 13.998q0-3.266 1.609-6.023t4.367-4.367 6.023-1.609 6.023 1.609 4.367 4.367 1.609 6.023z" />
                        </svg>
                    </a>
                    <a className="flex items-center justify-center w-12 h-12 p-3 ml-3 bg-gray-200 rounded-full dark:hover:bg-gray-600 group"
                        href="https://codepen.io/nikflip/"
                        target="_blank"
                        title="Niklas Köhler auf Github"
                        rel="noopener noreferrer">
                        <svg viewBox="0 0 512 512"
                            className="w-full h-auto fill-gray-500 dark:fill-gray-900 group-hover:fill-primary dark:group-hover:fill-gray-100">
                            <path
                                d="M472.875 184.021l-224-149.333a16 16 0 0 0-17.75 0l-224 149.333A16 16 0 0 0 0 197.333v149.333c0 5.35 2.674 10.346 7.125 13.312l224 149.333c2.687 1.792 5.781 2.688 8.875 2.688s6.188-.896 8.875-2.688l224-149.333A16 16 0 0 0 480 346.666V197.333a16 16 0 0 0-7.125-13.312zM240 327.438L156.844 272 240 216.563 323.156 272 240 327.438zm16-138.667V77.896l179.155 119.438L352 252.771l-96-64zm-32 0l-96 64-83.156-55.437L224 77.896v110.875zM99.156 272L32 316.771V227.23L99.156 272zM128 291.229l96 64v110.874L44.844 346.667 128 291.229zm128 64l96-64 83.156 55.438L256 466.104V355.229zM380.844 272L448 227.23v89.542L380.844 272z" />
                        </svg>
                    </a>
                </div>
            </figure>
        </div>
    </Layout>
)

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
